<template>
    <span>
        <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
            <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

                  <template v-slot:header>
                      <h4>Modifica {{ scuola.nome }} </h4>
                  </template>

                    <template v-slot:form>
                        <template v-if="!loading">
                      <div class="form">
                        <ErrorsAlert/>

                        <div class="field-group flex">
                          <label>Nome scuola</label>
                          <input type="text" name="nome" v-model="nome">
                        </div>
                        <div class="field-group flex">
                          <label>Istituto comprensivo <span class="required">*</span></label>
                          <multiselect v-model="istituto"
                             :options="getIstituti"
                             :show-labels="true"
                             label="nome"
                             track-by="nome"
                             placeholder="Scegli istituto comprensivo"></multiselect>
                         </div>

                         <div class="field-group flex">

                            
                          <label>Comune <span class="required">*</span></label>
                           <multiselect v-model="selected"
                             :options="getComuni"
                             :show-labels="true"
                             label="nome"
                             track-by="nome"
                             placeholder="Scegli il Comune"></multiselect>
                         </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                            <label>Località <span class="required">*</span></label>
                            <input type="text" name="localita" v-model="localita" required>
                          </div>
                        </div>
                        
                        <div class="form--action">
                            <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina scuola</button>
                            <button class="button button--small m-l-auto" @click.prevent="aggiornaScuola(scuola)" :disabled="disableSaveButton">Salva</button>
                        </div>
                        
                      </div>

                        </template>
                        <template v-else>
                            <UiPreloader/>
                        </template>

                    </template>

            </UiOffCanvas>


            <UiModal :modal="modalShow" :modalShow.sync="modalShow">
                <template v-slot:header>
                    <h4 class="modal--title">Sicuri di voler eliminare {{ scuola.nome }}?</h4>
                </template>

                <template v-slot:form>
                        <div class="form">
                        <ErrorsAlert />
                        <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ scuola.nome }}</strong>. <br/>Verranno eliminate tutte le classi di questa scuola</p>
                        <div class="field-group flex">
                                <div class="field col col-12">
                                    <input type="text" v-model="confermaEliminazione">
                                </div>
                        </div>
                        <div class="form--action">
                        <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                        <button class="button button--conferma" @click.prevent="eliminaScuola(scuola)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                        </div>
                        </div>
                </template>

            </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'NuovoIstituto',

    data() {
        return {
            disableSaveButton: false,
            offcanvasShow: false,
            modalShow: false,
            loading: false,
            nome: this.scuola.nome,
            istituto: this.scuola.istituto,
            selected: {
                nome: this.scuola.comune
            },
            localita: this.scuola.citta,
            confermaEliminazione: ''
        }
    },
    props: {
        scuola: Object
    },

    components: {
        ErrorsAlert,
        SuccessAlert
    },
    

    computed: {
        ...mapGetters('istituti',[
                'getIstituti'
            ]),

        ...mapGetters('comuni',[
                'getComuni'
        ]),
        
    },

    methods: {

        ...mapActions('scuole',[
            'updateScuola',
            'deleteScuola'
        ]),


        aggiornaScuola () {
            this.loading = true
            this.disableSaveButton = true

            this.updateScuola({
                id:  this.scuola.id,
                nome: this.nome,
                istituto_id: this.istituto.id,
                comune: this.selected.nome,
                citta: this.localita,
            }).then((response) => {
                 this.offcanvasShow = false
                 this.loading = false
                 this.disableSaveButton = false
                 this.$store.dispatch('scuole/fetchScuole')
            }).catch(e => {
                this.$store.state.success = null;
                this.loading = false
                this.$store.state.errors = e.response.data;
            })
        },

        eliminaScuola (scuola) {
            this.disableSaveButton = true
            this.deleteScuola(scuola).then((response) => {
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('scuole/fetchScuole')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

       

    }


}
</script>