<template>
    <span>
    <button class="button button--small" @click="modalShow = true">Nuova scuola</button>
          <UiModal :modal="modalShow" :modalShow.sync="modalShow">
                    <template v-slot:header>
                      <h4 class="modal--title">Nuova scuola</h4>
                    </template>

                    <template v-slot:content>
                      <template v-if="!loading">
                      <div class="form">
                         <ErrorsAlert />

                        <div class="field-group flex">
                          <div class="field col col-12">
                            <label>Nome scuola <span class="required">*</span></label>
                            <input type="text" name="nome" v-model="nome" ref="nome" required>
                          </div>
                        </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                             <label>Istituto comprensivo</label>
                             <multiselect v-model="istituto"
                             :options="getIstituti"
                             :show-labels="true"
                             :max-height="200"
                             label="nome"
                             track-by="nome"
                              placeholder="Scegli istituto comprensivo"></multiselect>
                          </div>
                        </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                             <label>Comune <span class="required">*</span></label>
                             <multiselect v-model="comune"
                             :options="getComuni"
                             :show-labels="true"
                             :max-height="200"
                             label="nome"
                             track-by="nome"
                              placeholder="Scegli il Comune"></multiselect>
                          </div>
                        </div>

                        <div class="field-group flex">
                          <div class="field col col-12">
                            <label>Località <span class="required">*</span></label>
                            <input type="text" name="localita" v-model="localita" required>
                          </div>
                        </div>

                        <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                                <button class="button button--conferma" @click.prevent="createScuola">Salva</button>
                       </div>

                

                      </div>

                      </template>
                      <template v-else>
                          <UiPreloader/>
                      </template>

                    </template>
          </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'NuovaScuola',

    data() {
        return {
            nome: '',
            loading: false,
            istituto: '',
            comune: '',
            localita: '',
            modalShow: false,
        }
    },

    props: {

    },


    watch: {
    // autofocus sull'input
    modalShow(val){

        if (val == true){
            // faccio focus sul primo campo
            this.$nextTick(() => {
                this.$refs.nome.focus()
            })
        } else {
            // svuoto i campi quando si chidue la modal
            this.nome = '',
            this.istituto = '',
            this.comune = '',
            this.localita = '',
            this.$store.state.errors = null
        }
      }
    },

    components: {
        ErrorsAlert
    },

    computed: {
        ...mapGetters('istituti',[
                'getIstituti'
            ]),

        ...mapGetters('comuni',[
                'getComuni'
        ]),
    },

    methods: {
    ...mapActions('scuole',[
        'addScuola'
    ]),

    createScuola() {
        this.loading = true
        this.addScuola({
            nome: this.nome,
            istituto_id: this.istituto.id,
            comune: this.comune.nome,
            citta: this.localita
        }).then((response) => {
            this.$store.dispatch('scuole/fetchScuole')
            this.loading = false
            this.modalShow = false
        }).catch(e => {
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
            this.loading = false
        })
     }

    },


    beforeMount() {
        this.$store.dispatch('istituti/fetchIstituti')
        this.$store.dispatch('comuni/fetchComuni')
    },

    beforeDestroy() {
        this.$store.state.scuola = null;
    }
}
</script>