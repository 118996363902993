<template>
  <div>
      <header>
        
          <h1 class="header--title"><span>Scuole</span> <span v-if="getScuole && getScuole.length > 0" class="badge badge--dark">{{ getScuole.length }}</span></h1>
          <div class="header--right">

            <div class="searchContainer">
              <input type="text" placeholder="Cerca" @keyup="cercaScuole()" v-model="key">
              <button @click="resetCercaScuole()" v-if="key">x</button>
            </div>

            <NuovaScuola />
          </div>

      </header>
      
        <table class="table" v-if="getScuole">
            <tr class="table--header">
                <th class="cell">
                  Nome
                </th>
                <th class="cell">
                  Istituto Comprensivo
                </th>
                <th class="cell">
                  Comune
                </th>
                <th class="cell">
                  Città / località
                </th>
                <th class="cell">
                  Azioni
                </th>
            </tr>
            <tr v-for="scuola in getScuole" :key="scuola.id" class="row">
                <td class="cell">
                <strong>{{ scuola.nome }}</strong>
                </td>

                <td class="cell">
                <span v-if="scuola.istituto">{{ scuola.istituto.nome }}</span><span v-else>-</span>
                </td>

                <td class="cell">
                  {{ scuola.comune }}
                </td>

                <td class="cell">
                  {{ scuola.citta }}
                </td>

                <td class="cell">
                 <ModificaScuola :scuola="scuola"/>
                </td>
              
            </tr>
        </table>
        <template v-else>
            <UiPreloader/>
        </template>
        

  </div>
</template>

<script>
// @ is an alias to /src
import NuovaScuola from '@/components/editor/NuovaScuola.vue'
import ModificaScuola from '@/components/editor/ModificaScuola.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'EditorScuole',

  data: () => {
    return {
      offcanvasShow: false,
      key: '',
    }
  },
  
  components: {
      NuovaScuola,
      ModificaScuola
  },


  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('scuole',[
        'getScuole',
        'getScuoleSearch'
     ]),

  },

  methods: {
    ...mapActions('scuole',[
        'fetchScuole'
    ]),

    cercaScuole() {
      this.tipo = '',
      this.key = event.target.value,
      this.getScuoleSearch(event.target.value)
    },

    resetCercaScuole() {
      this.tipo = '',
      this.key = '',
      this.getScuoleSearch('')
    },


  },

  beforeMount() {
    this.$store.dispatch('scuole/fetchScuole')
  }

}
</script>
